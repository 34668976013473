.footer {
	@apply h-10 screen:h-14 desktop:h-16 bottom-0 left-0 fixed flex bg-white w-full desktop:px-44 z-50;
	&_text {
		@apply font-light text-caption-4 leading-caption-4 screen:text-caption-3 screen:leading-caption-3 desktop:text-caption-2 desktop:leading-caption-2;
	}
}

.centered {
	@apply justify-center desktop:justify-center items-center;
}

.notcentered {
	@apply justify-center desktop:justify-center items-center;
}
