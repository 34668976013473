.accordion2 {
    /* Tab content - closed */

  
        // .scrollbar-styling{
        //     height: 40vh;
        //     overflow-y: scroll;
        //     scrollbar-width: none;
        // }

        // .scrollbar-styling::-webkit-scrollbar {
        //     display: none;
        // }

    .tab-content {
        max-height: 0;
        -webkit-transition: max-height .35s;
        -o-transition: max-height .35s;
        transition: max-height .35s;
    }

    /* :checked - resize to full height */
    .tab input:checked~.tab-content {
        max-height: 100vh;
    }

    /* Label formatting when open */
    .tab input:checked+label {
        /*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
        font-size: 1.25rem;
        /*.text-xl*/
        padding: 1.25rem;
        /*.p-5*/
        // border-left-width: 2px;
        // /*.border-l-2*/
        // border-color: #6574cd;
        /*.border-indigo*/
        background-color: radial-gradient(143.33% 143.33% at 82.41% -4.96%,
                rgba(132, 214, 163, 0.527) 0%,
                rgba(18, 28, 32, 0.3) 100%);
        /*.bg-gray-100 */
        // color: #6574cd;
        /*.text-indigo*/
    }

    /* Icon */
    .tab label::after {
        float: right;
        right: 0;
        top: 0;
        display: block;
        width: 1.5em;
        height: 1.5em;
        line-height: 1.5;
        font-size: 1.25rem;
        text-align: center;
        -webkit-transition: all .35s;
        -o-transition: all .35s;
        transition: all .35s;
    }

    /* Icon formatting - closed */
    .tab input[type=checkbox]+label::after {
        content: "+";

        /*.font-bold*/
        border-width: 1px;
        /*.border*/
        border-radius: 9999px;
        /*.rounded-full */
        border-color: #b8c2cc;
        /*.border-grey*/
    }

    .tab input[type=radio]+label::after {
        content: "\2713";
        margin-top: -20px;
        /*.font-bold*/
        border-width: 1px;
        /*.border*/
        border-radius: 9999px;
        /*.rounded-full */
        border-color: #b8c2cc;
        /*.border-grey*/
    }

    /* Icon formatting - open */
    .tab input[type=checkbox]:checked+label::after {
        transform: rotate(315deg);
        background-color: black;
        /*.bg-indigo*/
        color: #f8fafc;
        /*.text-grey-lightest*/
    }

    .tab input[type=radio]:checked+label::after {


        /*.bg-indigo*/
        background-color: #9fca35;
        color: radial-gradient(143.33% 143.33% at 82.41% -4.96%,
                rgba(132, 214, 163, 0.527) 0%,
                rgba(18, 28, 32, 0.3) 100%);
        /*.text-grey-lightest*/
    }

    .accordion_bg {
        background: #F0F0F0;
    }

    .accordion_bg_dark{
        background: #E1E0E0;
    }


}

