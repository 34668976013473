.cta {
  @apply h-screen bg-dark-400 flex;

  &_main {
    @apply flex justify-center items-center m-auto;
    &_level3 {
      &_button {
        @apply rounded-md tablet:rounded-lg flex px-3 py-1 desktop:px-6 desktop:py-2 items-center justify-center text-center w-fit-content cursor-pointer;
background: #bee55d;
        &_text {
          @apply mr-3 text-caption-1 screen:text-caption-1 screen:leading-caption-1 screen:p-1 screen:px-2 desktop:p-0 screen:font-bold laptop:text-paragraph-2 desktop:font-medium text-black;
        }
        &_icon {
          @apply w-3 text-black;
        }
      }
      &_capxillustration {
        @apply w-48 desktop:w-60 absolute -bottom-8 -right-24;
      }
    }
    &_level1 {
      &_button {
        @apply rounded-md screen:py-2 tablet:rounded-lg flex px-4 py-1 desktop:px-6 desktop:py-3 items-center justify-center text-center w-fit-content cursor-pointer;
        background: #bee55d; &_text {
          @apply mr-3 desktop:text-paragraph-2 tablet:text-caption-1 text-caption-1 font-medium text-white;
        }
        &_icon {
          @apply w-4 text-white;
        }
      }
      &_capxillustration {
        @apply w-48 desktop:w-60 absolute -bottom-8 -right-24;
      }
    }
  }
}
@media screen and (max-width: 320px) {
.cta_main_level1_button_text {
  font-size: 14px !important;
}
}