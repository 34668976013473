.header {

	.balance-div{
	background-color: #e5e4e4;
	}

	@apply desktop:h-20 screen:h-16 h-16 top-0 left-0 fixed flex w-full px-8 tablet:px-12 laptop:px-20 desktop:px-24 justify-between items-center bg-white;
	&_logo {
		@apply h-7 screen:h-7 desktop:h-8 twok:h-9;
	}
	&_navbar {
		@apply flex items-center text-white;
		&_text {
			@apply text-primary-green-100 laptop:text-paragraph-2 laptop:leading-paragraph-2 desktop:text-caption-1 desktop:leading-caption-1 font-semibold cursor-pointer;
		}
		&_gap {
			@apply bg-dark-200 h-14 mx-6 w-px border-dark-200;
		}
		&_button {
			@apply rounded-lg flex px-2 py-2 tablet:px-5 tablet:py-3 laptop:px-4 laptop:py-3 items-center justify-center text-center cursor-pointer w-48;
			background: #bee55d;
			&_text {
				@apply text-caption-3 leading-caption-3 tablet:text-caption-1 tablet:leading-caption-1 desktop:text-caption-2 desktop:leading-caption-2 font-medium;
			}
		}
		&_logoutbutton {
			background-color: #e5e4e4;
			@apply rounded-lg flex text-black items-stretch justify-center text-center;
			&_text {
				@apply text-caption-3 leading-caption-3 tablet:text-caption-3 tablet:leading-caption-3 desktop:text-caption-2 desktop:leading-caption-2 font-medium;
			}
			&_icon {
				@apply ml-2 h-5 mr-2 tablet:h-6 desktop:h-8 cursor-pointer;
			}
		}
	}
}
